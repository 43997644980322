@import "../../Screens/variables.scss";

.todo-list-week {
  background-color: rgba(204, 181, 154, 0.5);
  border-top: 4px solid $main-green;
  min-width: 375px;
  overflow: auto;
  height: calc(100vh - 140px);

  .headline {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 0 8px;
  }
  h2 {
    display: inline-block;
  }

  h2.label {
    flex: 1;
  }
  h3 {
    margin-top: 0px;
    padding: 10px;
  }

  @media only screen and (min-width: $min-width-desktop) {
    width: 400px;
    padding: 10px;
    margin: 0 10px;
  }
}
