@import "../../Screens/variables.scss";

.zwicon-button {
  cursor: pointer;
  display: inline-block;
  margin: 8px;

  &.button-right {
    position: absolute;
    right: 0;
  }

  &.button-cancel {
    .inner {
      .icon {
        background-color: unset;
        border: 1px $warning-red solid;

        i {
          color: $warning-red;
        }

        &:hover {
          background-color: $warning-red;
          border: 1px $warning-red solid;

          i {
            color: $white;
          }
        }
      }
    }
  }

  &.button-white {
    .inner {
      p {
        color: $white;
      }

      .icon {
        background-color: $white;
        border: 1px solid $white;

        i {
          color: $black;
        }
      }
    }
  }

  &.button-beige {
    .inner {
      p {
        color: $hellbeige;
      }

      .icon {
        background-color: $hellbeige;
        border: 1px solid $black;

        i {
          color: $black;
        }
      }
    }
  }

  .inner {
    display: flex;
    flex-direction: row;

    .icon {
      display: inline-block;
      width: 22px;
      height: 22px;
      padding: 2px 4px 4px 3px;
      border-radius: 16px;
      background-color: $main-green;
      border: 1px $main-green solid;

      i {
        font-size: 24px;
        color: $white;
      }

      &.icon-right {
      }

      transition: 0.2s;

      &:hover {
        background-color: $salat;
        border: 1px $salat solid;
      }
    }

    .label {
      margin: 0 10px;
      padding-top: 4px;
      font-family: "Karla", sans-serif;
    }

    .icon-transparent {
      background-color: transparent;
      border: 1.5px $main-green solid;

      i {
        color: $main-green;
      }
    }
  }

  .button-disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &.zwicon-button-smaller {
    .inner {
      .icon {
        width: 16px;
        height: 16px;
        padding: 3px 4px 4px 3px;
        border-radius: 16px;

        i {
          font-size: 16px;
        }
      }
    }
  }

  &.zwicon-button-56 {
    .inner {
      .icon {
        width: 47px;
        height: 47px;
        padding: 3px 4px 4px 3px;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          font-size: 36px;
        }
      }
    }
  }

  &.zwicon-button-48 {
    .inner {
      .icon {
        width: 40px;
        height: 40px;
        padding: 6px 0px 2px 8px;
        border-radius: 24px;
      }

      i {
        font-size: 32px;
      }
    }
  }
}
