@import "../../Screens/variables.scss";

.todos-group-row {
  display: flex;
  background-color: $white;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  cursor: pointer;

  img {
    height: 56px;
    width: 56px;
  }
  b {
    flex-grow: 1;
    padding-left: 8px;
  }
  .icon {
    font-size: 32px;
    padding: 0 16px;
    color: $main-green;
  }
}

.todos-group-list {
  &.hide {
    .todo-item {
      display: none;
    }
  }
  &.open {
    .todos-group-row {
      margin-bottom: 0;
      border-bottom: 1px solid $black;
    }
    .empty-list {
      background-color: $hellbeige;
      margin-top: 0;
      padding: 4px 16px 4px 56px;
      border-bottom: 1px solid $light-blue;
    }
    .todo-item:last-child {
      margin-bottom: 16px !important;
      border-bottom: 0;
    }
  }
  .MuiCheckbox-root {
    color: $black;
  }
  .todo-content-title {
    color: $dark-grey;
  }
  .todo-item {
    background-color: $hellbeige;
    margin-bottom: 0;
    min-height: 57px;
    border-bottom: 1px solid $light-blue;

    .todo-right {
      font-size: 32px;
      padding: 0 16px;
      color: $main-green;
    }
    .crop-image {
      background-color: $white;
      min-width: 56px;
      align-items: center;
      display: flex;

      p {
        padding: 0;
      }
      img {
        height: 56px;
        width: 56px;
        margin: auto;
      }
      i {
        font-size: 24px;
      }
    }
  }
}
