.react-calendar__tile--active.react-calendar-tile-hack, .react-calendar__tile--now.react-calendar-tile-hack {
    overflow: visible !important;
}
.react-calendar__month-view__weekNumbers {
    flex-shrink: 1 !important;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    flex: 0 0 14.2857% !important;
    padding-top: 2px !important;
    padding-bottom: 3px !important;
}

