@import "../../Screens/variables.scss";

div.secondary-navigation {
  position: fixed;
  display: flex;
  z-index: 10000;
  width: calc(100% - 48px);
  left: 0;
  max-width: 1125px;
  height: 80px;
  background-color: $hellbeige;
  align-items: center;
  flex-direction: row;
  bottom: 0;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  box-shadow: 0 -2px 2px -2px rgba(0, 0, 0, 0.3);

  &.full-screen {
    @media only screen and (min-width: $min-width-desktop) {
      width: calc(100% - 298px);
      left: 250px;
    }
  }
}

.dialog-navigation {
  display: flex;
  width: calc(100% - 48px);
  left: 0;
  max-width: 1125px;
  height: 80px;
  background-color: $hellbeige;
  align-items: center;
  flex-direction: row;
  bottom: 0;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
}