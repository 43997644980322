$max-width: 1280px;
$header-height: 60px;
$min-width-desktop: 600px;

$menu-width: 220px;
$mobile-menu-height: 80px;
$mobile-container-height: calc(100% - 75px);

$light-blue: #bad8ce;
$bright-blue: #58aad4;
$blue: #2175a1;

$light-green: #65d083;
$light-green-hover: #7be298;
$main-green: #13905b;
$main-green-hover: #2da572;
$aubergine: #593c57;
$aubergine-medium: #765673;
$aubergine-light: #9a7e98;
$aubergine-midi: #796571;
$zitrone: #e8e376;
$salat: #6fc180;
$beige: #f4e5d1;
$beige2: #c1b3a4;
$beige2-lighter: #e0cdb6;
$dark-beige: #e0ccb5;
$midi-beige: #ead9c3;
$hellbeige: #f5f0ea;
$mittelgrau: #e4e4e4;
$black: #150807;

$light-red: #e74d32;
$warning-red: #cc0033;
$red: #852005;
$orange: #f8af4a;
$white: #ffffff;
$beige-trans: #f4e5d1f3;

$grey: #8c8c8c;
$dark-grey: #8c8c8c;
$light-grey: #999999;
$light-grey2: #cacaca;
$light-grey3: #f2f2f2;

$size-h1: 32px;
$size-h2: 24px;
$size-h3: 20px;
$size-h4: 16px;
$size-copy: 16px;
$size-label: 14px;
$size-navigation: 12px;

@mixin font-vollkorn {
  font-family: "Vollkorn", serif;
}

@mixin font-karla {
  font-family: "Karla", serif;
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

@mixin trans-fade {
  -o-transition: 0.3s;
  -ms-transition: 0.3s;
  -moz-transition: 0.3s;
  -webkit-transition: 0.3s;
}

@mixin paper {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #dddddd;
  background-color: $white;
  filter: drop-shadow(0px 2px 3px rgba(100, 100, 100, 0.1));
}

@mixin shadow {
  filter: drop-shadow(0px 4px 3px rgba(0, 0, 0, 0.2));
}

@mixin mobile-buttons {
  .button-container {
    width: 100%;

    .button-secondary {
      @include font-vollkorn();
      font-size: $size-copy;
      font-weight: bold;
      letter-spacing: 0.5px;
      text-align: center;
      width: calc(100% - 32px);
      color: $blue;
      height: 46px;
      border: 1px $blue solid;
      border-radius: 23px;
      margin: 0px 16px;
    }

    .button-continue {
      @include font-vollkorn();
      font-size: $size-h3;
      font-weight: bold;
      letter-spacing: 1px;
      padding-top: 2px;
      height: 46px;
      border-radius: 23px;
      width: calc(100% - 32px);
      margin: 16px;
      background-color: $main-green;
      color: $white;
      text-align: center;

      &:hover {
        background-color: $main-green-hover;
      }

      &.disabled {
        opacity: 0.5;
        transition: opacity 0.2s;
        transition-delay: 0.2s;
      }
    }
  }
}

@mixin navigation-label {
  font-family: Karla, serif;
  font-weight: 700;
  font-size: $size-copy;
  line-height: 24px;
}

@mixin details-and-labels {
  font-family: Karla, serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@mixin crop-detail-mixin {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  height: auto;
  margin-top: 20px;
  margin-bottom: 8px;
  h2 {
    margin: 8px 0;
  }
  .crop-image-container {
    display: flex;
    align-items: center;
    min-height: 125px;
    overflow: hidden;
    margin-bottom: 8px;
    width: calc(100% - 175px);
    &.full-width {
      width: 100% !important;
    }
    .edit-name {
      display: flex;
      margin-top: 10px;
      cursor: pointer;
      i {
        font-size: 24px;
        margin-right: 10px;
        cursor: pointer;
      }
      p {
        margin: 0;
      }
    }
    .is-approved {
      display: flex;
      margin-top: 10px;
      cursor: pointer;
      align-items: center;
      .MuiCheckbox-root {
        padding: 0;
        margin-right: 10px;
      }
    }
  }
  .crop-text {
    width: calc(100% - 176px);
    h2 {
      font-size: $size-h2;
    }
    h2,
    h4 {
      margin: 0;
      color: #000000;
      max-height: 40px;
      white-space: nowrap;
    }
    .date-info {
      margin: 5px 0px;
    }
    h2,
    h4,
    span {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
    .rating {
      margin-top: 8px;
      display: flex;
      flex-direction: row;
      .rating-text {
        margin-left: 4px;
        &.count {
          color: $grey;
        }
      }
    }
    .creator {
      margin-top: 8px;
    }
  }
  .crop-image {
    position: relative;
    margin-right: 16px;
    .img-preview {
      background-size: 160px;
      width: 160px;
      height: 160px;
      border-radius: 50%;
    }
    img:first-child {
      width: 160px;
      height: 160px;
      min-width: 160px;
      min-height: 160px;
      background-color: $white;
      border-radius: 50%;
      object-fit: cover;
    }
    .my-seed-label {
      position: absolute;
      width: 32px;
      height: 32px;
      top: 0;
      left: 95px;
    }
  }
  .crop-actions {
    flex: 1;
    background-color: $midi-beige;
    padding: 16px 16px 0px;
    min-width: 242px;
    max-width: 300px;
    border-radius: 10px;
    margin-left: 10px;
    .action {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      cursor: pointer;
      &.delete {
        i {
          color: $light-red;
        }
      }
      &.approve {
        i {
          color: $main-green;
        }
      }
      img,
      i {
        height: 24px;
        width: 24px;
        margin-right: 10px;
        font-size: 24px;
      }
    }
  }
}

:export {
  aubergine: $aubergine;
  aubergineDark: $aubergine;
  aubergineMedium: $aubergine-medium;
  aubergineLight: $aubergine-light;
  aubergineMidi: $aubergine-midi;
  lightRed: $light-red;
  mainGreen: $main-green;
  mainGreenHover: $main-green-hover;
  salat: $salat;
  hellbeige: $hellbeige;
  lightBeige: $hellbeige;
  darkBeige: $dark-beige;
  darkestBeige: $beige2;
  beige: $beige;
  black: $black;
  lightBlue: $light-blue;
  blue: $blue;
  brightBlue: $bright-blue;
  beige2: $beige2;
  beige2lighter: $beige2-lighter;
  white: $white;
  lightGreen: $light-green;
  lightGreenHover: $light-green-hover;
  beigeTrans: $beige-trans;
  midiBeige: $midi-beige;
  grey: $grey;
  lightGrey: $light-grey;
  lightGrey2: $light-grey2;
  lightGrey3: $light-grey3;
  darkGrey: $dark-grey;
  orange: $orange;
  sizeh1: $size-h1;
  sizeh2: $size-h2;
  sizeh3: $size-h3;
  sizeh4: $size-h4;
  sizeCopy: $size-copy;
  sizeLabel: $size-label;
  sizeFootnote: $size-label;
  sizeNavigation: $size-navigation;
  fontKarla: "Karla", serif;
  fontVollkorn: "Vollkorn", serif;
}
