@import "Screens/variables.scss";

html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
}

html {
  span,
  div,
  p {
    font-size: 16px;
  }
}

p {
  line-height: 24px;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: "Karla", sans-serif;
  overflow-y: hidden;
  background-color: $beige;
}
// style to disable double tap zoom on all elements
* {
  touch-action: manipulation;
}

h1,
h2,
h3,
h4 {
  @include font-vollkorn();
  color: $black;
}

h1 {
  font-size: 28px;
  margin-top: 12px;
  margin-bottom: 10px;
}

h2 {
  font-size: 22px;
}

#root {
  min-height: 100%;
  height: 100%;
}

.root-wrapper {
  margin: auto;
}

.content-wrapper {
  position: relative;
  background-color: $beige;
  height: 100%;
  overflow: hidden;

  &.full-width {
    max-width: 100%;
  }
}

.content {
  display: flex;
  flex-direction: row;
  height: calc(100% - 60px);
}

.mainContent {
  flex: 1;
  padding-left: 10px;
  padding-right: 10px;
  overflow-y: auto;

  &.noscroll {
    overflow-y: hidden;
  }
}

.height-flex {
  display: flex;
  flex-direction: column;
}

.flex {
  flex: 1;
}

.flex-col {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.with-footer {
    height: calc(100% - 100px);
  }
}

.flex-height {
  flex: 1;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-item {
  flex: 1;
}

.container-inner {
  padding: 16px;
  height: calc(100% - 95px);
  &.menu {
    margin-left: 224px;
  }
  &.no-menu {
    height: 100% !important;
  }

  @media only screen and (min-width: $min-width-desktop) {
    height: 100%;
  }

  &.no-padding {
    padding: 0;
  }
}

.scroll {
  overflow-y: auto;
}

.textfield-100 {
  width: 100%;
}

.center {
  text-align: center;
}

.form-row {
  margin-bottom: 20px;
}

.paper {
  padding: 10px;
  margin-bottom: 10px;
  background-color: $hellbeige;
  filter: drop-shadow(0px 2px 3px rgba(100, 100, 100, 0.1));
}

.app-bar {
  background-color: $main-green !important;
  min-height: 48px;

  h1 {
    font-size: 20px;
    margin: 12px 0 0 0;
    text-transform: uppercase;
  }

  .toolbar {
    padding-left: 10px;
    display: flex;
  }
}

.modal-content {
  background-color: $beige;
  position: absolute;
  max-width: 800px;
  top: 50%;
  width: calc(100% - 36px);
  transform: translate(18px, -50%);

  @media only screen and (min-width: $min-width-desktop) {
    width: 400px;
    height: 400px;
    left: calc(50% - 200px);
    overflow-y: auto;
  }
}

.button-bar {
  position: relative;
  padding-bottom: 10px;
  height: 32px;
}

.statusbar-bg {
  width: 100%;
  height: 16px;
  background-color: $white;
}

.statusbar-fg {
  background-color: $main-green;
  height: 100%;
}
.close-button {
  position: absolute;
  padding-bottom: 10px;
  right: 8px;
  height: 32px;
  top: 20px;
  z-index: 1;
  svg {
    height: 20px;
    width: 20px;
  }
}

.browserNotSupported-page {
  &.mobile {
    padding: 16px;
  }
  padding: 100px;
  font-size: 24px;
  line-height: 1.5em;
  a {
    color: $main-green;
    text-decoration: none;
    &:hover {
      color: $light-blue;
    }
  }
}
.MuiTabs-root {
  min-height: 36px !important;
  .MuiTabs-flexContainer {
    height: 36px;
    border-bottom: 2px solid $beige2-lighter;
    .MuiButtonBase-root.MuiTab-root {
      min-height: 36px;
      @media only screen and (min-width: $min-width-desktop) {
        &:first-child {
          border-left: 0;
        }
        &:last-child {
          border-right: 0;
        }
        border-color: $beige2-lighter;
        border-style: solid;
        border-width: 0 2px 0 2px;
        background-color: $hellbeige;
      }

      &.Mui-selected {
        font-weight: bold;
        background-color: rgba(57, 128, 102, 0.4);
      }
      .MuiTab-wrapper {
        color: $black;
        text-transform: none;
      }
    }
  }
}

.error-content {
  height: 100%;
  background-color: $hellbeige;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  img {
    width: 100%;
    max-width: 600px;
  }
  h3 {
    margin-top: -60px;
  }
  @media only screen and (min-width: $min-width-desktop) {
    h3 {
      margin-top: -80px;
    }
  }
}
