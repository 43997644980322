@import "../../Screens/variables.scss";

.custom-todo {
  background-color: $hellbeige;
  padding: 16px;
  margin-bottom: 20px;
  &.edit {
    width: 100%;
  }
  .custom-todo-title {
    display: flex;
    padding-bottom: 10px;
    input {
      height: 28px;
    }
  }
  .content-block {
    display: flex;
    align-items: center;
    .col {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      margin: 4px 0 0 16px;
      i {
        color: $white;
      }
      .save {
        display: flex;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        align-items: center;
        font-size: 30px;
        font-weight: bold;
        background-color: $main-green;
      }
    }
  }
}
