@import "../../Screens/variables.scss";

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.custom-modal {
  overflow-y: auto;
  outline: none;
  padding: 10px 5px;

  .custom-modal-content {
    height: calc(100% - 82px);
    padding: 20px;
    h1 {
      font-size: 28px;
      margin-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &.fullScreen {
    padding: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transform: none;
  }
}
