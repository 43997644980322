@import "../../Screens/variables.scss";

.todo-options {
  height: 100%;
  padding: 16px;
  .container-scroll {
    overflow: auto;
    height: calc(100% - 160px);
    margin-right: -16px;
    padding-right: 16px;
  }
  .option-container {
    margin-bottom: 16px;
    padding: 16px;
    background-color: $hellbeige;
    h3 {
      color: $main-green;
      margin: 0 0 16px;
    }
    .MuiFormControlLabel-label {
      font-family: "Karla";
      font-weight: bold;
    }
    .switch-with-label {
      display: flex;
      font-weight: bold;
      justify-content: space-between;
      align-items: center;
    }
    .todo-views {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 200px;
      .option {
        position: relative;
        .zwicon-button {
          display: none;
        }
        &.active {
          box-shadow: inset 0 0 0 3px $main-green;
          .zwicon-button {
            display: block;
            position: absolute;
            right: 0;
            bottom: 0;
          }
        }
      }
      .one-todo-view {
        background-color: $dark-beige;
        flex: 1;
        margin-right: 8px;
        padding: 12px;
        .img-title-placeholder {
          display: flex;
          margin-top: 16px;
          align-items: center;
          .img {
            background-color: white;
            width: 35px;
            height: 35px;
            position: relative;
            border-radius: 50%;
            .inner {
              background-color: $light-red;
              top: 25%;
              left: 25%;
              width: 50%;
              height: 50%;
              position: relative;
              border-radius: 50%;
            }
          }

          .title-placeholder {
            flex: 1;
            height: 12px;
            border-radius: 6px;
            background-color: $dark-grey;
            margin-left: 12px;
          }
        }
        hr {
          border: 4px solid $dark-grey;
          border-radius: 4px;
          margin-top: 12px;
          &:last-child {
            margin-left: 0;
            width: 60%;
          }
        }
      }
      .todo-list-view {
        background-color: $dark-beige;
        flex: 1;
        padding: 12px;
        margin-left: 8px;
        .list {
          margin-top: 24px;
        }
        .img-title-container {
          height: 16px;
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          background-color: $hellbeige;

          .img {
            background-color: $white;
            height: 16px;
            width: 16px;
            .inner {
              background-color: $light-red;
              top: 25%;
              left: 25%;
              width: 50%;
              height: 50%;
              position: relative;
              border-radius: 50%;
            }
          }
          .title {
            flex: 1;
            height: 4px;
            border-radius: 2px;
            background-color: $dark-grey;
            margin: 0 8px;
          }
        }
      }
    }
    .MuiSwitch-root {
      width: 60px;
      height: 52px;
      display: inline-flex;
      padding: 10px 4px 14px 12px;

      .MuiSwitch-switchBase {
        color: $white;
        opacity: 1;
        &.Mui-checked + .MuiSwitch-track {
          background-color: $main-green;
          opacity: 1;
        }
        .MuiSwitch-thumb {
          width: 28px;
          height: 28px;
        }
        + .MuiSwitch-track {
          background-color: $light-blue;
          opacity: 1;
          border-radius: 14px;
        }
      }
    }
  }
  .watering-panel {
    p {
      margin: 0;
    }
    .MuiTextField-root {
      input {
        font-weight: bold;
      }
    }
    .time-label {
      padding-top: 5px;
      display: inline-block;
    }
  }
  .options-footer {
    position: fixed;
    display: flex;
    z-index: 1000;
    width: 100%;
    left: 0;
    max-width: 1280px;
    height: 80px;
    justify-content: space-between;
    align-items: center;
    background-color: $hellbeige;
    flex-direction: row;
    bottom: 0;
    .col {
      display: flex;
      flex-direction: column;
      width: 32px;
      height: 32px;
      cursor: pointer;
      border-radius: 50%;
      align-items: center;
      font-size: 30px;
      font-weight: bold;
      margin-bottom: 20px;
      &:first-child {
        margin-left: 40px;
      }
      &:last-child {
        margin-right: 40px;
      }
      span {
        padding-top: 2px;
      }
      i {
        color: $white;
      }
      &.save {
        background-color: $main-green;
      }
      &.cancel {
        i {
          color: $dark-grey;
          font-size: 36px;
          line-height: 28px;
        }
      }
    }
  }
}
.todo-option-details {
  .button-bar {
    position: absolute;
    padding-bottom: 10px;
    right: 8px;
    height: 32px;
    top: 24px;
  }
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 100;
}
