@import "../../Screens/variables.scss";

.todo-item {
  display: flex;
  flex-direction: row;

  .snooze-todo {
    display: flex;
    font-size: 32px;
    font-weight: bold;
    cursor: pointer;
    p {
      padding: 16px;
    }
    i {
      padding: 10px 0 10px 16px;
    }
  }

  p.todo-location {
    color: $aubergine;
    margin: 0;

    &.done {
      color: $light-blue;
      text-decoration: line-through;
    }
  }
  .custom-footer {
    display: flex;
    padding: 0 16px 16px 16px;
    justify-content: space-between;
    align-items: center;
    i {
      font-size: 32px;
      color: $light-red;
      cursor: pointer;
    }
    .icon-with-label {
      display: flex;
      align-items: center;
      font-weight: bold;
      cursor: pointer;
      i {
        color: $black;
        padding-right: 16px;
      }
    }
  }
  p {
    margin-top: 0;
    margin-bottom: 8px;
  }

  .todo-content {
    display: flex;
    align-items: stretch;
    flex: 1;
    height: auto;
    position: relative;
    cursor: pointer;
    min-height: 56px;

    p.todo-title {
      font-weight: bold;
      margin: 0px;
      padding: 12px 50px 12px 0;

      &.done {
        color: $light-blue;
        text-decoration: line-through;
      }
    }

    .todo-content-title {
      display: flex;
    }
  }
  .centered-todo-items {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .todo-text {
    display: flex;
    flex-direction: row;
    .crop-image {
      flex-direction: column;
      justify-content: flex-end;
    }
    .additional-information {
      display: flex;
      padding: 4px 16px;
      &:first-child {
        padding-top: 12px;
      }
      .title {
        font-weight: bold;
        min-width: 100px;
        flex: 1;
      }
    }
    p {
      padding: 16px;
    }
  }
  .todo-right {
    position: absolute;
    right: 0px;
    i {
      cursor: pointer;
      &:hover {
        color: $main-green;
      }
    }
    .zwicon-info-circle {
      color: $black;
    }
  }
}
