@import "../../Screens/variables.scss";
.scroll.work {
  height: calc(100% - 20px);
  .slick-slider {
    height: calc(100% - 100px);
  }
  &.mobile {
    height: calc(100% - 90px);
    padding: 0;
    > .todo-dialog-title {
      min-height: 40px;
      margin: 16px 0;
      h1 {
        margin: 0;
      }
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-wrapper {
        font-size: 32px;
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: center;
        line-height: 40px;
        width: 40px;
        background-color: $main-green;
        border-radius: 50%;
        i {
          color: $white;
        }
      }
    }
    overflow-y: scroll;
    .scroll-wrapper {
      outline: none;
      height: 100%;
      overflow: auto;
      display: block;
    }
  }
}

.scroll-wrapper {
  padding: 0 16px;
  width: calc(100vw - 32px) !important;
}

.todoWorkWeek {
  display: table;
  width: 100%;
  height: 40px;
  .weeks-tab {
    display: flex;
    .week {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      color: $black;
      line-height: 40px;
      width: 33.33%;
      font-size: 14px;
      cursor: pointer;
      border-bottom: 2px solid $beige2;
      &.selected {
        font-weight: bold;
        border-bottom: 2px solid $main-green;
        background-color: rgba(57, 128, 102, 0.4);
      }
      &.current-week {
        .lastWeek + span {
          display: block;
          line-height: 20px;
          padding-left: 40px;
        }
        .nextWeek + span {
          padding-right: 24px;
          padding-left: 20px;
          line-height: 20px;
          display: block;
        }
      }
    }
  }
  .lastWeek {
    color: $main-green;
    font-size: 32px;
    cursor: pointer;
    position: absolute;
    left: 0;
    top: 0;
  }
  .showDate {
    text-align: center;
    width: 120px;
    height: 25px;
    background: $main-green;
    border-radius: 1.5px;
    margin-left: 5px;
    color: $white;
    p {
      margin-top: 2px;
    }
  }

  .nextWeek {
    color: $main-green;
    font-size: 32px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.selected-week {
  color: $black;
  font-weight: bold;
  margin-bottom: 16px;
}
.work-container-header {
  display: flex;
  justify-content: space-between;
  background-color: rgba(204, 181, 154, 0.5);
  align-items: center;
  height: 60px;
  margin: -16px -16px 20px -16px;
  .profile-box {
    padding-left: 8px;
    @media only screen and (max-width: 1050px) {
      min-width: 100px;
    }
  }
  .icon-with-label {
    height: 60px;
    padding-left: 8px;
    margin: 0 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-left: 1px solid $black;
    .icon-wrapper {
      display: flex;
      height: 26px;
      align-items: center;
      justify-content: center;
      line-height: 26px;
      width: 26px;
      background-color: $main-green;
      margin-right: 8px;
      border-radius: 50%;
      i {
        font-size: 22px;
        color: $white;
      }
    }
  }

  .todoWorkWeek {
    width: auto;
    margin-left: 24px;
    @media only screen and (max-width: 1100px) {
      margin-left: 8px;
    }
    .week {
      width: 140px;
      &.current-week {
        .lastWeek + span {
          display: block;
          line-height: 20px;
          padding-left: 40px;
        }
        .nextWeek + span {
          padding-right: 24px;
          padding-left: 20px;
          line-height: 20px;
          display: block;
        }
      }
      @media only screen and (max-width: 1200px) {
        min-width: 100px;
        width: 100px;
      }
    }
  }
}

.ackerhelden-mobile {
  margin: 20px 0;

  .ackerhelden-banner-content {
    font-size: 14px;
    line-height: 20px;
    color: $white;
  }

  .ackerhelden-logo {
    margin: 0 20px 0 5px;
    width: 56px;
    height: 56px;
  }
}

.ackerhelden-desktop {
  margin-left: auto;
  margin-right: 12px;
  height: 48px;
  min-width: 200px;
  @media only screen and (max-width: 1300px) {
    height: 54px;
  }
  .ackerhelden-banner-content {
    font-size: 12px;
    line-height: 120%;
    color: $white;
  }

  .ackerhelden-logo {
    margin: 0 5px;
    width: 32px;
    height: 32px;
  }
}

.ackerhelden-banner {
  display: flex;
  align-items: center;
  flex-flow: row;
  padding: 0 12px;
  background-color: $main-green;
}

.slick-next {
  right: 0;
  height: 40px;
  top: -20px;
  padding-left: 33%;
  &:before {
    content: "";
  }
}
.slick-prev {
  height: 40px;
  left: 0;
  top: -20px;
  padding-right: 33%;
  &:before {
    content: "";
  }
}
