@import "../../Screens/variables.scss";

.todo-list-container {
  overflow-x: auto;
  .work-navigation {
    display: flex;
    padding-left: 10px;
    margin-bottom: 10px;

    .rect-button {
      cursor: pointer;
      background-color: $main-green;
      padding: 2px 4px;
      margin-right: 10px;
      color: $white;
    }
  }
}

.todo-list {
  min-height: 100%;

  @media only screen and (min-width: $min-width-desktop) {
    display: flex;
    overflow-y: unset;
  }
}
