div.navigation-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  .zwicon-button {
    margin: auto;
  }

  span {
    font-weight: bold;
    padding-top: 6px;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &.hidden {
    visibility: hidden;
  }
}
